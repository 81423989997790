import React from 'react';
import './Contact.css';
import { useState, useRef} from 'react';
import {useForm} from 'react-hook-form';
import emailjs from 'emailjs-com';

export default function Contact() {

const form = useRef();
const [formData, setFormData] = useState({name: "", email: "", message: ""});
const [submitStatus, setSubmitStatus] = useState(false);
const {register, handleSubmit, formState:{errors}} = useForm();

function handleInput(e){
    const { name, value } = e.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

function handleForm(e){
    emailjs.sendForm('service_lzsalt5', 'template_882le5p', form.current, 'dXhHJg1NME6NbZEJz')
    .then((result) => {
      console.log(result.text);
      alert('success');
    }, (error) => {
      console.log(error.text)
      alert('failed..');
    });
    setSubmitStatus(true);
};

 
  return (
    <div className="brandon__contact" id="contact">
        <h1 className="brandon__contact_heading first">Contact</h1>
        <h1 className="brandon__contact_heading second">Get in touch</h1>
        <div className="brandon__contact_text">
            <p>For any inquiries, feel free to reach out to me below.</p>
        </div>

      { !(submitStatus) &&
        <form ref={form} className="brandon__contact_form" onSubmit={handleSubmit(handleForm)}>
            <div className="brandon__contact_form_field">
            <label htmlFor="Name">Name</label>
            <input type="text" id="name" name="name" {...register("name", {required: true})} value={formData.name}onChange={handleInput}/>
            {errors.name && <p>Your name is required.</p>}
            </div>
            <div className="brandon__contact_form_field">
            <label htmlFor="Email">Email</label>
            <input type="email" id="email" name="email" {...register("email", {required: true})} value={formData.email}onChange={handleInput}/>
            {errors.name && <p>A valid email is required.</p>}
            </div>

            <div className="brandon__contact_form_field message">
            <label htmlFor="Message">Message</label>
            <textarea type="text" id="message" name="message" {...register("message", {required: true})} value={formData.message}onChange={handleInput}/>
            {errors.name && <p>A message is required.</p>}
            </div>

            <button className="brandon__contact_form_submit"type="submit">Submit</button>
        </form>
      }
      { submitStatus &&
      <div className="brandon__contact_form_status">
        <p>Your message has been successfully submitted!</p>
        </div>
      }
    </div>
  )
}
