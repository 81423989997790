import heroImg from '../assets/images/certified-brandon.png';
import ghostThumb from '../assets/images/ghost-thumbnail.png';
import nbThumb from '../assets/images/nb-thumbnail.png';
import cbLogo from '../assets/images/city-bonfires-logo.png';
import kpLogo from '../assets/images/kalaia-logo.png';
import cpLogo from '../assets/images/carepod-logo.png';
import tlLogo from '../assets/images/taglock-logo.png';

export default{
    heroImg, ghostThumb, nbThumb, cbLogo, kpLogo, cpLogo, tlLogo
}