import React from 'react'
import './Collabs.css'
import brands from '../../constants/brands';
import { Collab } from '../../components';

export default function Collabs() {
  return (
    <div className="brandon__collabs" id="collabs">
        <h1 className="brandon__collabs_heading first">Collabs</h1>
        <h1 className="brandon__collabs_heading second">Brands Worked With</h1>
        <div className="brandon__collabs_container">
            {
              brands.map( brand => {
                return (
                  <Collab logo={brand.logo} stack={brand.stack} name={brand.name} year={brand.year} desc={brand.desc}/>
                )
              })
            }
        </div>
    </div>
  )
}
