import React from 'react';
import './TechStack.css';
import { Category } from '../../components';
import categories from '../../constants/categories';

export default function TechStack() {
  return (
    <div className="brandon__techstack" id="techstack">
        <h1 className="brandon__techstack_heading first">Tech Stack</h1>
        <h1 className="brandon__techstack_heading second">Languages, frameworks, etc.</h1>
        <div className="brandon__techstack-categories">
            {categories.map(item => {
                return (
                    <Category name={item.category_name} tech={item.items}/>
                )
            })}
        </div>
    </div>
  )
}
