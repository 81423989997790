import React from 'react';
import './Project.css';

export default function Project({thumbnail, name, desc, stack, link}) {
  return (
    <div className="brandon__project">
      <a href={link} target='__blank'>
    <div className="brandon__project_thumbnail">
        <img src={thumbnail}/>
        <div className="brandon__project_over-text">
          <h3>{name}</h3>
        </div>
    </div>
    </a>
    <div className="brandon__project_name-stack">
        <h3 className="brandon__project_name">
          {name}
        </h3>
        <div className="brandon__project_stack">
              {stack.map( item => {
                return (
                <div className="brandon__project_stack-item">
                  {item}
                </div>
                )
              })}
        </div>
    </div>

    <div className="brandon__project_desc-container">
        <p>{desc}</p>
    </div>
    <a className="brandon__project__view-btn-link"href={link} target='__blank'>
    <button className="brandon__project_view-btn"> View Project</button>
    </a>
    </div>
  )
}
