import React from 'react'
import './Projects.css'
import images from '../../constants/images';
import { Project } from '../../components';
import data from '../../constants/data';

export default function Projects() {
  return (
    <div className="brandon__projects" id="projects">
        <h1 className="brandon__projects_heading first">Projects</h1>
        <h1 className="brandon__projects_heading second">Recent Work</h1>
        <div className="brandon__projects_proj-container">
          {data.map(project => {
            return(
              <Project thumbnail={project.thumb} name={project.name} desc={project.desc} stack={project.stack} link={project.link}/>
            )
          })}
        </div>
    </div>
  )
}
