import React from 'react';
import './Category.css';
import {PiStarFourFill} from 'react-icons/pi'

export default function Category({name, tech}) {
  return (
    <div className="brandon__category">
        <h2 className="brandon__category-heading">{name} <PiStarFourFill size={15}/></h2>
        <div className="brandon__category_tech-container">{tech}</div>
        <hr />
    </div>
  )
}
