import './App.css';
import { Hero, Projects, TechStack, Collabs, Contact, Navbar } from './containers';

function App() {
  return (
    <div className="App">
      <Navbar/>
      <Hero/>
      <Projects/>
      <TechStack/>
      <Collabs/>
      <Contact/>
    </div>
  );
}

export default App;
