import React from 'react'
import './Collab.css';

export default function Collab({logo, stack, name, year, desc}) {
  return (
    <div className="brandon__collab">
        <div className="brandon__collab_logo">
            <img src={logo}/>
        </div>
        <div className="brandon__collab_info">
        <div className="brandon__collab_brand-stack">
        <h3 className="brandon__collab_brand">
          {name}
        </h3>
        <div className="brandon__collab_stack">
        {stack.map( item => {
                return (
                <div className="brandon__collab_stack-item">
                  {item}
                </div>
                )
              })}
        </div>
        </div>
        <div className="brandon__collab_year">
           <h4>{year}</h4>
        </div>
        <div className="brandon__collab_desc-container">
            <p>{desc}</p>
        </div>
        </div>
    </div>
  )
}
