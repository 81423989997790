import {FaReact, FaCss3, FaHtml5, FaWordpress, FaBootstrap} from 'react-icons/fa';
import {BiLogoJavascript, BiLogoPython, BiLogoJquery} from 'react-icons/bi';
import {SiWebflow, SiAdobephotoshop, SiAdobeillustrator, SiMysql, SiMongodb} from 'react-icons/si'
import {FiFigma} from 'react-icons/fi'


export default [
    {
        category_name: 'Languages',
        items: [
            <div className="brandon__category_item"><h4><BiLogoJavascript size={25} style={{marginRight: '5px'}}/>Javascript</h4></div>,
            <div className="brandon__category_item"><h4><FaCss3 size={25} style={{marginRight: '5px'}}/>CSS</h4></div>,
            <div className="brandon__category_item"><h4><FaHtml5 size={25} style={{marginRight: '5px'}}/>HTML</h4></div>,
            <div className="brandon__category_item"><h4><BiLogoPython size={25} style={{marginRight: '5px'}}/>Python</h4></div>
        ]
    },
    {
        category_name: 'Libraries',
        items: [
            <div className="brandon__category_item"><h4><FaReact size={25} style={{marginRight: '5px'}}/>React</h4></div>,

        ]
    },
    {
        category_name: 'Frameworks',
        items: [
            <div className="brandon__category_item"><h4><BiLogoJquery size={25} style={{marginRight: '5px'}}/>jQuery</h4></div>,
            <div className="brandon__category_item"><h4><FaBootstrap size={25} style={{marginRight: '5px'}}/>Bootstrap</h4></div>,
        ]
    },
    {
        category_name: 'CMS',
        items: [
            <div className="brandon__category_item"><h4><FaWordpress size={25} style={{marginRight: '5px'}}/>Wordpress</h4></div>,
            <div className="brandon__category_item"><h4><SiWebflow size={25} style={{marginRight: '5px'}}/>Webflow</h4></div>,
        ]
    },
    {
        category_name: 'Design',
        items: [
            <div className="brandon__category_item"><h4><FiFigma size={25} style={{marginRight: '5px'}}/>Figma</h4></div>,
            <div className="brandon__category_item"><h4><SiAdobephotoshop size={25} style={{marginRight: '5px'}}/>Photoshop</h4></div>,
            <div className="brandon__category_item"><h4><SiAdobeillustrator size={25} style={{marginRight: '5px'}}/>Illustrator</h4></div>,
        ]
    },
    {
        category_name: 'Database',
        items: [
            <div className="brandon__category_item"><h4><SiMysql size={25} style={{marginRight: '5px'}}/>MySQL</h4></div>,
            <div className="brandon__category_item"><h4><SiMongodb size={25} style={{marginRight: '5px'}}/>MongoDB</h4></div>,
        ]
    },
];