import images from "./images";
import {FaReact, FaCss3, FaHtml5, FaWordpress} from 'react-icons/fa';
import {BiLogoJavascript} from 'react-icons/bi';
import {SiLeaflet} from 'react-icons/si';

export default [
    {
        name: 'GHOST Energy Revamp',
        thumb: images.ghostThumb,
        stack: [<FaReact/>, <BiLogoJavascript/>, <FaCss3/>, <FaHtml5/>, <SiLeaflet/>],
        link: 'https://ghost.brichdev.com',
        desc: 'A revamped ecommerce version of the Ghost Energy website that allows for visitors to view products, add them to cart, and locate stores where the products are sold.'
    },

    {
        name: 'New Balance x Inside Voices',
        thumb: images.nbThumb,
        stack: [<FaReact/>, <BiLogoJavascript/>, <FaCss3/>, <FaHtml5/>],
        link: 'https://insidevoices.brichdev.com',
        desc: 'A sneaker launch page that shows information on a new sneaker release in an aesthetically-pleasing manner while providing resources on how to the purchase said sneaker.'
    }
];