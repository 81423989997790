import React from 'react';
import './Hero.css'
import images from '../../constants/images';
import {FaReact, FaCss3, FaHtml5, FaWordpress} from 'react-icons/fa';
import {BiLogoJavascript} from 'react-icons/bi';

export default function Hero() {
  return (
    <div className="brandon__hero">
        <div className="brandon__hero_container">
          <div className="brandon__hero_left">
            <div className="brandon__hero_name">
            <h1 className="brandon__hero_heading first">Brandon</h1>
            <h1 className="brandon__hero_heading second">Richardson</h1>
            </div>
            <h3 className="brandon__hero_heading_title">Front End Developer + Designer</h3>
            <div className="brandon__hero_text-block">
              <p className="brandon__hero_text">I've been developing and designing aesthetically-pleasing user experiences for 5+ years.</p>
            </div>

            <div className="brandon__hero_tech-stack">
              <div className="brandon__hero_tech-logo">
                <FaReact/>
              </div>
              <div className="brandon__hero_tech-logo">
                <BiLogoJavascript/>
              </div>
              <div className="brandon__hero_tech-logo">
                <FaCss3/>
              </div>
              <div className="brandon__hero_tech-logo">
                <FaHtml5/>
              </div>
              <div className="brandon__hero_tech-logo">
                <FaWordpress/>
              </div>
            </div>
            <a href='#projects'><button className="brandon__hero_btn">See Work</button></a>
          </div>
          <div className="brandon__hero_right">
            <div className="brandon__hero_img">
              <img src={images.heroImg}/>
            </div>
          </div>
        </div>
    </div>
  )
}
