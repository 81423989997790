import {FaReact, FaCss3, FaHtml5, FaWordpress, FaBootstrap, FaShopify} from 'react-icons/fa';
import {BiLogoJavascript, BiLogoPython, BiLogoJquery} from 'react-icons/bi';
import {SiWebflow, SiAdobephotoshop, SiAdobeillustrator, SiMysql, SiMongodb} from 'react-icons/si'
import {FiFigma} from 'react-icons/fi';
import images from './images';



export default [
    {
        name: 'KALAIA Products',
        year: '2022',
        desc: 'Designed and launched a promotional, conversion-focused landing page for one of their star products. Variations were made for A/B testing and CRO purposes.',
        stack: [<FaShopify/>, <FaHtml5/>, <BiLogoJquery/>, <FaCss3/>],
        logo: images.kpLogo,

    },
    {
        name: 'Carepod',
        year: '2022 - 2023',
        desc: 'Designed a promotional, conversion-optimized landing page for their signature Carepod One humidifier.',
        stack: [<FaShopify/>, <FaHtml5/>, <BiLogoJquery/>, <BiLogoJavascript/>, <FaCss3/>],
        logo: images.cpLogo,
    },
    {
        name: 'City Bonfires',
        year: '2022',
        desc: 'Designed a conversion-optimized landing page for their signature product in partnership with a full-service digital agency.',
        stack: [<FaShopify/>, <FaHtml5/>, <FaCss3/>],
        logo: images.cbLogo,
    },
    {
        name: 'Taglock',
        year: '2023',
        desc: 'Designed and launched a promotional, conversion-optimized landing page for their AirTag wallet. Variations were made for A/B testing and CRO purposes.',
        stack: [<FaShopify/>, <FaHtml5/>, <FaCss3/>],
        logo: images.tlLogo,
    }
];