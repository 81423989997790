import React, {useState} from 'react';
import './Navbar.css';
import {RiMenuFill, RiCloseLine} from 'react-icons/ri';

export default function Navbar() {

    const [toggleMenu, setToggleMenu] = useState(false);
  return (
    <div className="brandon__navbar">
        <div className="brandon__navbar-links">
            <div className="brandon__navbar-links_container">
                <p><a href="#projects">Projects</a></p>
                <p><a href="#techstack">Tech Stack</a></p>
                <p><a href="#collabs">Collabs</a></p>
                <p><a href="#contact">Contact</a></p>
            </div>
        </div>
        <div className="brandon__navbar-menu">
            {toggleMenu ? <RiCloseLine color="#000" size={27} onClick={() => setToggleMenu(false)}/> :
                            <RiMenuFill color="#000" size={27} onClick={() => setToggleMenu(true)}/>}
            {toggleMenu && (
                <div className="brandon__navbar-menu_container">
                    <div className="brandon__navbar-menu_container-links">
                        <p onClick={()=> setToggleMenu(false)}><a href="#projects">Projects</a></p>
                        <p onClick={()=> setToggleMenu(false)}><a href="#techstack">Tech Stack</a></p>
                        <p onClick={()=> setToggleMenu(false)}><a href="#collabs">Collabs</a></p>
                        <p onClick={()=> setToggleMenu(false)}><a href="#contact">Contact</a></p>
                    </div>
                </div>
            )}
        </div>
    </div>
  )
}
